<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPurchasesDeliveryItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="purchasesDelivery.status === DELIVERY_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("PURCHASES_DELIVERIES.ADD_PURCHASES_DELIVERY_ITEM") }}
        </base-button>
      </div>
    </div>

    <purchases-delivery-view-details-purchases-delivery-item-table
      :purchasesDelivery="purchasesDelivery"
      @onOpenPurchasesDeliveryItemModal="openPurchasesDeliveryItemModal"
    />

    <div class="row mb-5" v-if="purchasesDelivery.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesDelivery.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="purchasesDelivery.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesDelivery.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in purchasesDelivery.pricing.discounts
                .details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="purchasesDelivery.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesDelivery.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in purchasesDelivery.pricing.taxes.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesDelivery.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <purchases-delivery-view-details-purchases-delivery-item-form
      :purchasesDelivery="purchasesDelivery"
      :purchasesDeliveryItem="purchasesDeliveryItem"
      :showModal="showModal"
      @onClosePurchasesDeliveryItemModal="closePurchasesDeliveryItemModal"
      @onSubmitPurchasesDeliveryItemForm="handlePurchasesDeliveryItemFormSubmit"
      @onDeletePurchasesDeliveryItem="deletePurchasesDeliveryItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import {
  DELIVERY_STATUS_CANCELED,
  DELIVERY_STATUS_DRAFT,
} from "@/constants/deliveries";
import PurchasesDeliveryViewDetailsPurchasesDeliveryItemForm from "./PurchasesDeliveryViewDetailsPurchasesDeliveryItemForm.vue";
import PurchasesDeliveryViewDetailsPurchasesDeliveryItemTable from "./PurchasesDeliveryViewDetailsPurchasesDeliveryItemTable.vue";
import defaultPurchasesDeliveryItem from "../defaultPurchasesDeliveryItem";

export default {
  name: "purchases-delivery-view-details",

  components: {
    PurchasesDeliveryViewDetailsPurchasesDeliveryItemForm,
    PurchasesDeliveryViewDetailsPurchasesDeliveryItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["purchasesDelivery"],

  data() {
    return {
      purchasesDeliveryItem: cloneDeep(defaultPurchasesDeliveryItem),
      loading: false,
      showModal: false,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_CANCELED: DELIVERY_STATUS_CANCELED,
    };
  },

  computed: {},

  methods: {
    openPurchasesDeliveryItemModal(purchasesDeliveryItem = null) {
      if (!purchasesDeliveryItem) {
        this.purchasesDeliveryItem = cloneDeep(defaultPurchasesDeliveryItem);
      } else {
        this.purchasesDeliveryItem = cloneDeep(purchasesDeliveryItem);
      }
      this.showModal = true;
    },

    closePurchasesDeliveryItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handlePurchasesDeliveryItemFormSubmit(purchasesDeliveryItemData) {
      if (!purchasesDeliveryItemData.id) {
        this.addPurchasesDeliveryItem(purchasesDeliveryItemData);
      } else {
        this.editPurchasesDeliveryItem(purchasesDeliveryItemData);
      }
    },

    async addPurchasesDeliveryItem(purchasesDeliveryItemData) {
      this.loading = true;
      try {
        purchasesDeliveryItemData.purchasesDelivery.id = this.purchasesDelivery.id;

        await this.$store.dispatch(
          "purchasesDeliveryItems/add",
          purchasesDeliveryItemData
        );
        this.$emit("purchasesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async editPurchasesDeliveryItem(purchasesDeliveryItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesDeliveryItems/update",
          purchasesDeliveryItemData
        );
        this.$emit("purchasesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async deletePurchasesDeliveryItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("purchasesDeliveryItems/destroy", id);
        this.$emit("purchasesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
